
.footer-wrap {
	background-color: #2A3035;
	position: relative !important;
	margin: 0 auto !important;
	visibility: hidden; // initially hide visibility for the entire footer in order for the semantic-ui fade in animation to work when top margin of the footer is in view
	.footer {
		color: white; // color for text inside footer
		.row:first-of-type {
			padding: 80px 0 80px 0 !important; // padding for the footer excluding the row with copyright text
			.column {
				// footer headers
				h4 {
					font-weight: 900 !important; // very bold
					color: #2185D0; } // blue
				&:nth-of-type(2) {
					h4 {} }

				.list {
					.item {
						// font-weight: 900 !important
						a {
							color: white;
 } } }							// font-weight: 900 !important
				// various optimizations for the items inside footer columns to show at the same level
				&:nth-of-type(1) {
					div.item {
						padding: 19px 0; } }
				&:nth-of-type(2) {
					div.item {
						padding: 10px 0;
						&:nth-of-type(5) {
							.content {
								.header {
									font-size: 13.2px !important; } } } } }

				&:nth-of-type(3) {
					margin-top: 20px;
					div.item {
						padding: 10px 0; } }
				&:nth-of-type(4) {
					div.item {
						padding: 19px 0;
						&:nth-of-type(3) {
							// background-color: red
							padding: 13px 0; }
						&:last-of-type {
							padding: 0;
							.image {
								// bring the third column of the footer to the same level with other columns (needed because the header was removed)
								.header {
									position: relative;
									margin-top: -33px !important; } } }
						&.presentation__title {
							padding: 30px 0 8px 0;
							i {
								display: none; }
							.content {
								padding: 0;
								.header {
									font-family: 'Open Sans';
									font-weight: 400;
									font-size: 16px;
									color: #26a2fd !important; } } }
						&.presentation__sociallinks {
							padding: 10px 0;
							.social_icons {
								margin-top: 16px !important;
								a {
									i {
										padding: 0 24px 0 24px; }
									&:nth-of-type(1) {
										i {
											padding: 0px; } }
									&:nth-of-type(2) {
										i {
											margin-top: 3px; } } } } } } } } } } }

// bottom row of the footer with copyright text
.ui.grid.copyright {
	.column {
		padding: 24px 0 35px 0;
		background-color: #20282D;
		color: lightgray; } }

// mobile view
@media screen and (max-width: 767px) {
	.footer {
		img.ui.mini.image {
			display: inline !important; }
		.content.header-font {
			font-size: 14px !important; }
		.row:first-of-type {
			.column {
				// some optimizations in order for column 2 and 3 of the footer to appear only as one column on mobile without spaces between them.
				&:nth-of-type(2) {
					padding-bottom: 0px !important; }
				&:nth-of-type(3) {
					padding-top: 0px !important;
					h4.item:nth-child(1) {
						border-bottom: 1px solid rgba(63, 68, 73,0.9); }
					.item:nth-child(1) {
						border-top: 0px;
						padding-top: 0px !important; } } } } } }





// all views except mobile
@media screen and (min-width: 768px) {
	.footer {
		img.ui.mini.image {
			display: none; }
		.content.header-font {
			font-size: 14px; } } }

// ipad pro
@media (max-width: 1024px) and (min-width: 768px) {
	.presentation__title {
		.content {
			.header {
				font-size: 14px !important; } } }
	.footer {
		img.ui.mini.image {
			display: none !important; } }

	.presentation__sociallinks {
		padding: 10px 0;
		.social_icons {
			margin-top: 16px !important;
			a {
				i {
					font-size: 24px;
					padding: 0 16px 0 16px !important; }
				&:nth-of-type(1) {
					i {
						padding: 0px !important; } }
				&:nth-of-type(2) {
					i {
						padding-left: 10px !important;
						margin-top: 3px; } } } } } }
// ipad
@media (max-width: 991px) and (min-width: 768px) {
	.presentation__sociallinks {
		padding: 10px 0;
		.social_icons {
			margin-top: 16px !important;
			a {
				i {
					font-size: 24px;
					padding: 0 14px 0 14px !important; }
				&:nth-of-type(1) {
					i {
						padding: 0px !important; } }
				&:nth-of-type(2) {
					i {
						margin-top: 3px; } } } } } }


// all views starting from small monitors -- except mobile and tablet
@media screen and (min-width: 991px) {
	// various optimizations in order for the items inside footer columns to appear at the same line with the company logo (needed because the text for some items was too long and thus it snapped under the logo)
	.footer {
		img.ui.mini.image {
			display: inline; }
		.row {
			.column:nth-of-type(2) {
				.item {
					&:nth-of-type(1) {
						.content.header-font {
							font-size: 13.5px; } }
					&:nth-of-type(4) {
						.content.header-font {
							font-size: 11.4px; } }
					&:nth-of-type(5) {
						.content.header-font {
							font-size: 12.1px; } } } }
			.column:nth-of-type(3) {
				.item {
					&:nth-of-type(4) {
						.content.header-font {
							.description {
								font-size: 11.3px; } } } } } } } }


// all views starting from large monitors -- except mobile, tablet and laptop
@media screen and (min-width: 1201px) {
	// various optimizations in order for the items inside footer columns to appear at the same line with the company logo (needed because the text for some items was too long and thus it snapped under the logo)
	.footer {
		img.ui.mini.image {
			display: inline; }
		.row {
			.column:nth-of-type(2) {
				.item {
					&:nth-of-type(1) {
						.content.header-font {
							font-size: 14px; } }
					&:nth-of-type(4) {
						.content.header-font {
							font-size: 14px; } }
					&:nth-of-type(5) {
						.content.header-font {
							font-size: 14px; } } } }
			.column:nth-of-type(3) {
				.item {
					&:nth-of-type(4) {
						.content.header-font {
							.description {
								font-size: 14px !important; } } } } } } } }


