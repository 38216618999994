
a.item.segment {
	padding: 0 !important; }

.divisions {
	margin: 50px 0 !important;
	.column {
		.ui.header {
			font-family: 'Open Sans';
			font-weight: 200;
			font-size: 2.5rem;
			padding: 0 0 40px 0;
			&::before {
	            position: absolute;
	            border-bottom: 1px solid white;
	            width: 100px;
	            margin-left: -45px;
	            -webkit-transition: .4s ease-in-out; } } } }

.divi-drop {
	&:nth-child(1),
	&:nth-child(5),
	&:nth-child(9),
	&:nth-child(13) {
		.column:nth-of-type(2) {
			padding-left: 40px; } } }

.division-box {
	.content {
		.ui.image {
			.divi-btn {
				background-color: red !important; } } } }

.divisions-overview {
	margin-bottom: 50px;
	h2 {
		padding: 30px 0 !important;
		font-weight: 100 !important;
		font-size: 2.5rem !important; }

	.grid {
		.column:nth-of-type(2) {
			.content {
				.header {
					i {
						border-right: 1px solid lightgray;
						padding-right: 20px;
						font-size: 3em;
						color: #4c4c4c; }
					.content {
						padding-left: 18px !important;
						a {
							.header {
								color: #1F6DCA;
								font-family: 'Open Sans';
								font-weight: 100; }
							.header:hover {
								color: #4c4c4c; } } } }
				.sub.header {
					&:nth-of-type(1), {
						font-size: 20px;
						font-weight: 400;
						font-family: helvetica;
						.content {
							padding-left: 7px !important; } }
					&:nth-of-type(2),
					&:nth-of-type(3) {
						font-size: 18px;
						text-align: justify; } }

				.description {
					&:nth-of-type(2) {
						margin-top: 20px !important;
						font-size: 18px !important; }
					&:nth-of-type(3) {
						display: none;
						font-size: 18px !important;
						margin-top: 20px; } }
				.extra {
					margin-top: 20px; } } } } }
.divi-drop {
	visibility: hidden;
	padding: 40px 0 !important;
	&:first-of-type {
		padding-top: 0 !important; } }

.hideReadmore {
	display: none !important; }
.ui.divider.padding-between-divisions {
	margin: 30px 0; }

#showhidemenu.segments {
	margin: 0 !important;
	border: none !important;
	box-shadow: none !important;
	visibility: hidden;
	&:nth-of-type(1),
	&:nth-of-type(3),
	&:nth-of-type(2), {
		.segment:not(.cancel-style) {
			overflow: hidden;
			.content {
				position: absolute;
				visibility: hidden;
				bottom: -50;
				left: 0;
				padding: 20px;
				background-color: #2185d0;
				width: 100%;
				color: white;
				-webkit-transition: 0.5s;
				transition: 0.5s;
				opacity: 0;
				z-index: 999; }
			.divi-btn {
				position: absolute;
				opacity: 0;
				left: 30%;
				bottom: 20%;
				z-index: 999;
				transition: 0.5s;
				vertical-align: middle !important; }
			img {
				-webkit-transition: transform 0.5s;
				transition: transform 0.5s;
				box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.4) !important; }
			&:hover .content {
				opacity: 1;
				-webkit-transition: transform .5s;
				transition: transform .5s;
				-webkit-transform: translate3d(0,-50px,0);
				transform: translate3d(0,-50px,0);
				visibility: visible; }
			&:hover img {
				-webkit-transform: translate3d(0,-50px,0);
				transform: translate3d(0,-50px,0); }
			&:hover .divi-btn {
				opacity: 1;
				-webkit-transform: translate3d(0,-70px,0);
				transform: translate3d(0,-70px,0); }
			&:hover img {
				opacity: 0.2;
				-webkit-transition: 0.5s ease !important;
				transition: 0.5s ease; } } } }

@media screen and (max-width: 767px) {
	.segments {
		.segment.division-box {
			margin: 2px 0 !important; } }

	.grid {
		.column:nth-of-type(2) {
			.content {
				.list {
					.item {
						i {
							font-size: 2.7em; }
						.content {
							.header {
								font-size: 1.3em; } } } }
				.description {
					&:nth-of-type(1), {
						font-size: 12px !important; }
					&:nth-of-type(2),
					&:nth-of-type(3) {
						font-size: 14px !important;
						text-align: justify; } } } } }
	.cancel-style {
		display: none !important; }

	.divi-btn {
		bottom: 22% !important;
		left: 32% !important; } }

@media (max-width: 991px) and (min-width: 768px) {
	#showhidemenu {
		.division-box {
			.content {
				padding: 14px 10px !important;
				h3 {
					font-size: 1em !important; } }
			&:hover img {
				-webkit-transform: translate3d(0,-40px,0) !important;
				transform: translate3d(0,-40px,0) !important; } } }
	.divi-btn {
		bottom: -5% !important;
		left: 20% !important; }

	.divisions-overview {
		.grid {
			.column:nth-of-type(2) {
				.content {
					.header {
						i {
							font-size: 2.6em; }

						.content {
							a {
								.header {

									font-size: 1.8rem !important; } } } }

					.sub.header {
						&:nth-of-type(1), {
							font-size: 1.1rem !important; } }
					.description {
						&:nth-of-type(2) {
							font-size: 100% !important; }
						&:nth-of-type(3) {

							font-size: 100% !important; } } } } } } }



@media screen and (max-width: 1200px) {
	#showhidemenu.segments {
		&:nth-of-type(1),
		&:nth-of-type(3),
		&:nth-of-type(2) {
			.segment:not(.cancel-style) {
				.content {
					padding: 15px;
					h3 {
						font-size: 16px; }
					&:hover .content {
						-webkit-transform: translate3d(0,-60px,0) !important;
						transform: translate3d(0,-60px,0) !important; } }

				.divi-btn {
					bottom: 10%;
					left: 26%; } } } }
	.divi-drop {
		padding: 50px 0 !important; }
	.divisions-overview {
		.grid {
			.column:nth-of-type(2) {
				.content {
					.header {
						i {
							font-size: 2.6em; }

						.content {
							a {
								.header {

									font-size: 2rem; } } } }

					.sub.header {
						&:nth-of-type(1), {
							font-size: 1.3rem; } }
					.description {
						&:nth-of-type(2) {
							font-size: 120%; }
						&:nth-of-type(3) {

							font-size: 120%; } } } } } } }
