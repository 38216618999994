// the icon visible on hover over the url
i.ui.right.angle.icon {
	// color: #3FB4AF
	font-size: 30px;
	text-align: center;
	color: white; }

a.ui.animated.fade.button.fluid {
	background-color: #194993;
	color: white;
	padding: 20px;
	border-radius: 0; }

// each row that is animated
.company-row {
	margin-bottom: 50px !important;
	// visibility: hidden // initially hide for semantic ui transition
	.column {
		.card {
			.content {
				background-color: #194993;
				font-family: 'Raleway';
				font-weight: 600;
				letter-spacing: 1px;
				word-spacing: 1px; }
			img {
				padding: 20px 0 10px 0;
				background-color: white; }
			.segment {
				height: 115px;
				background-color: #eee;
				span {
					top: 0px;
					position: relative; } }
			&:hover {
				box-shadow: 0px 0px 56px -13px rgba(0,0,0,0.75);
				cursor: pointer; } } } }

.romanian-based {
	padding-top: 100px !important; }
#ro,
#usa {
	position: relative;
	padding-left: 0px !important;
	.container {
		padding-left: 0px !important; } }
#ro {
	background-color: #F7F8F9; }
#usa {
	padding: 0px 0 30px 0;
	.row {
		padding-bottom: 40px; } }

// top raised hero header
.infocard {
	background-color: #F7F8F9 !important;
	color: #194993 !important;
	padding: 60px 0 !important;
	font-size: 22px !important;
	border-radius: 7px !important;
	margin-top: -128px !important;
	// text-shadow: 0.5px 0.5px 0.5px black !important
	visibility: hidden; // initially hide for semantic-ui transition
	// visibility: hidden
	.grid .column .description {
		padding: 10px 0 0 0 !important; }
	.header {
		color: #194993;
		font-size: 32px !important;
		.content {
			font-weight: 100; } } }

.infolink {
	margin: 60px 0 !important;
	.ui.header {
		font-family: 'Open Sans';
		font-weight: 200;
		font-size: 2.2rem;

 } }		// margin-left: 60px

.section.group-companies-labels {
	position: absolute;
	// left: -6px
	width: 100%; }


.ro-entity {
	z-index: 1 !important; }

// divided sections titles
.ro-entity,
.us-entity {
	z-index: 9999 !important;
	.sectionTitle { // add .floating class to align subtitles to the left outside the container on wide screens
		span {
			background-color: transparent;
			margin: 16px 1em;
			padding: 0;
			color: #194993;
			z-index: 99 !important;
			font-size: 1.2em;
			font-weight: 900;
			letter-spacing: 1; } }
	&.fixed {
		padding: 14px;
		background-color: #fff;
		box-shadow: 0 0 12px 0 rgba(0,0,0,.3);
		left: 0 !important;
		transition: 0.5s ease; } }

#usa .section.group-companies-labels {
	bottom: 20px; }
#ro .section.group-companies-labels {
	top: 15px; }

// very large screens only
@media screen and (min-width: 1920px) {
	.section .container.floating { // align subtitles to the left outside the container on wide screens
		width: 20% !important;
		text-align: right;
		margin: 0 !important; } }

// small monitors only
@media (max-width: 1199px) and (min-width: 992px) {
	.company-row {
		&:nth-of-type(2) {
			.column {
				&:nth-of-type(2) {
					.card {
						.segment {
							padding-top: 0px !important; } } } } } } }


// mobile only
@media screen and (max-width: 767px) {
	.section .container.sectionTitle { // add .floating class to align subtitles to the left outside the container on wide screens
		text-align: center; }

	.ro-entity {
		&.fixed {
			font-size: 0.7em; } }
	#ro .section.group-companies-labels {
		top: 20px; }

	.infocard {
		padding: 20px 0 0 0 !important;
		font-size: 14px !important;
		// visibility: hidden
		.grid .column .description {
			padding: 5px 0 30px 0 !important; }
		.header {
			font-size: 16px !important; } } }


// tablet only
@media (max-width: 991px) and (min-width: 768px) {
	.usa-based {
		.company-row {
			.column {
				&:nth-of-type(1) {
					.card {
						.segment {
							padding-top: 2px !important; } } }
				&:nth-of-type(2) {
					.card {
						img {
							padding: 27px 0; } } } } } }

	.romanian-based {
		.company-row {
			margin-bottom: 50px;
			.column .card .segment {
				height: 152px;
				span {
					top: 30px; } }
			&:nth-of-type(2) {
				.column:nth-of-type(1) {
					.card {
						img {
							padding: 35px 0; } } }
				.column:nth-of-type(2) {
					.card {
						img {
							padding: 35px 0; }
						.segment {
							height: 152px;
							span {
								top: 0px !important;
								position: relative; } } } } } } }

	a.ui.animated.fade.button.fluid {
		font-size: 10px; }

	.infocard {
		padding: 40px 0 !important;
		font-size: 14px !important;
		// visibility: hidden
		.grid .column .description {
			padding: 5px 0 5px 0 !important; }
		.header {
			font-size: 20px !important; } } }

.lower-card {
  margin-top: 208px !important; }

#geobetaCard {
  margin-bottom: 208px !important; }

.cardContainer {
  padding-top: 1px !important;
  padding-bottom: 1px !important; }

.imobContainer {
  margin-top: 20px !important; }

#usa {
  margin-top: 30px !important; }

#usaTitle {
  border-bottom: dashed 1px #000; }
