.date {
    float:right;
}
.justify {
    text-align: justify;
}

.cc-message {
  font-weight: 300 !important;
}

.cc-link {
  font-weight: bold;
  text-decoration: none !important
}

.cc-btn {
  border-radius: 5px;
  border-width: 1px !important;
  font-weight: 300 !important;
}