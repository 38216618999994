// hero header
.hero-contact {
	width: 100%;
	margin: auto;
	.white.inverted.header {
		font-family: 'Open Sans', sans-serif;
		// background: rgba(0,0,0, 0.5)
		padding: 50px 0px;
		background: linear-gradient(to left,  transparent , #15588A, transparent); } }

.form-wrap {
	visibility: hidden !important; // initially hide for semantic ui transition
	.ui.form {
		width: 100%;
		margin: auto;
		z-index: 100;
		background-color: #fff;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		border: 1px solid lightgray;
		padding: 40px 20px 50px 20px;
		margin-top: -140px;
		margin-bottom: 0px; }
	.sub.header {
		margin-bottom: 40px;
		font-size: 16px; }
	.ui.header {
		font-family: 'Open Sans';
		margin: 40px 0 0 0 {
	    display: inline-block;
	    position: relative; } }

	.field {
		label {
			font-size: 16px; }
		.input {
			input {
				transition: 0.5s ease !important;
				font-size: 16px; } }
		textarea {
			font-size: 16px;
			height: 40px; } }
	.bottom.attached.segment {
		width: 100%;
		margin: auto;
		border-top: none;
 } }		// margin-top: 50px
.bottom.attached.segment {
	.grid {
		.column {
			padding: 50px 0; } } }

#ContactButton {
	font-family: 'Open Sans';
	&:hover {
		color: white !important;
		background-color: #2185D0 !important; } }

.g-recaptcha {
	float: right;
	margin-bottom: 20px; }

// google map
#map {
	height: inherit;
	margin-top: 0px !important;
	margin-bottom: 15px; }
.scrollFix {
	overflow: hidden; }
// information popup over the map
.info {
	width: 340px;
	.row {
		.column {
			ul {
				li {
					list-style-type: none;
					font-size: 16px;
					padding: 5px 0;
					&:last-of-type {
						margin-bottom: 20px; } } } } } }
// map has an overlay in order to prevent the user
// to zoom the map when is with the cursor over it
// and lets the zoom to happen after the user clicks on the map one time
.overlay-map {
	background: transparent;
	position: absolute;
	width: 100%;
	height: 712px;
	z-index: 101;
	margin-top: 0px !important; }

// wrapper around form and map
.segmentation {
	width: 98% !important;
	margin: 0 auto !important;
	.grid {
		.column:nth-of-type(1) {
			padding-right: 0px !important;
			.form {
				padding: 0 30px; } }
		.column:nth-of-type(2) {} } }

// mobile only
@media screen and (max-width:767px) {
	#map {
		margin-bottom: 20px !important; }

	.g-recaptcha {
		float: left; }

	.form {
		.fields:last-of-type {
			.field {
				&:last-of-type {
					margin: 20px 0 40px 0px !important; } } } }

	.overlay-map {
		margin-top: 122px !important;
		height: 505px !important; } }

// all screens with the exception of desktops
@media screen and (max-width: 1200px) {
	.g-recaptcha {}
	.form {
		padding: 40px 20px 80px 20px !important;
		.fields:last-of-type {
			display: block !important;
			.field {
				&:first-of-type {
					width: 100% !important; }
				&:last-of-type {
					margin: 20px 0 40px 130px; } } } } }
