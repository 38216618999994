.materials_container {
	margin: -140px auto 40px auto;
	background-color: #fff;
	padding: 10px 24px 14px 24px;
	border-radius: 5px;
	border: 1px solid lightgray; }
.materials_header {
	padding: 20px 0;
	margin: 0;
	background-color: #eaeaea;
	font-family: 'Open Sans';
	font-weight: 100;
	font-size: 28px;
	color: #3B83C0;
	text-align: center; }
.materials_header_main {
	padding: 10px 0 !important;
	color: #2185d0 !important;
	font-size: 2rem !important;
	font-family: 'Open Sans' !important; }
.materials_header_pdfs {
	margin-top: 10px !important;
	color {} }
.materials_header_pdfs, .materials_header_videos {
	font-size: 24px;
	font-weight: 100;
	margin-top: 20px; }

.materials_list_videos, .materials_list_pdfs {
	padding-bottom: 40px !important;
	.item {
		padding: 18px 20px !important;
		margin-top: 10px;
		box-shadow: 0px 0px 1px gray;
		border-radius: 5px;
		transition: .3s ease-in-out;
		&:hover {
			box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
			transition: .3s ease-in-out; }
 } }		// background-color: #eaeaea

.hero-material {
	width: 100%;
	margin: auto; }


// mobile only
@media screen and (max-width:767px) {
	.materials_header_main {
		font-size: 1.2rem !important; }
	.materials_header_pdfs, .materials_header_videos {
		font-size: 16px; } }
