// variables for hero headers on all pages but the homepage
$hero-padding: 10% 0;
$hero-padding-mobile: 100px 0;

body {
  background-color: white;
  font-size: 14px;
  line-height: 1.6;
  font-family: 'Open Sans', sans-serif;
  color: #666;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0; }

// hero headers
.hero-contact,
.hero-material,
.hero-divisions,
.hero-about-us,
.hero-companies {
  padding: $hero-padding;
  background-size: cover !important;
  box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0) !important;
  background-repeat: no-repeat !important;
  visibility: hidden; // initially hide for semantic-ui transition
  h1 {
    padding: 20px 0;
    margin-top: 20px;
    background: linear-gradient(to left,  transparent, transparent, #3B83C0, transparent , transparent); } }
// hero header images for all pages
.hero-companies {
  background: url('../../assets/img/header-companies.jpg') center center; }
.hero-divisions {
  background: url('../../assets/img/header-divisions.jpg') center center; }
.hero-about-us {
  background: url('../../assets/img/header-about-us.jpg') center center; }
.hero-contact {
  background: url('../../assets/img/header-contact-us.jpg') center center; }
.hero-material {
  background: url('../../assets/img/header-material.jpg') center center; }

.hero-material,
.hero-companies,
.hero-about-us,
.hero-contact {
  padding: 190px 0 !important; }
* {
  font-family: 'Open Sans'; }

.content-wrapper {
  position: relative !important;
  .grid {
    .hero-header {
      padding-bottom: 0px !important;
      color: white; } } }

// links
a {
  &:hover,
  &:focus {
    color: lighten(#0074D9, 20%); } }

// iOS fix
html.ios {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

html.ios,
html.ios body {
  height: initial; }

// language dropdown menu
.dropdown {
  .vertical.menu {
    .header {
      background-color: white;
      text-transform: none;
      letter-spacing: 1;
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 14px;
      margin: 0;
      padding: 16px; }
    .dropped.menu {
      .item:first-of-type {
        margin-top: 0 !important; }
      .item {
        padding: 14px !important;
        &.active {
          background-color: #eee !important; } } } } }

// mobile only
@media screen and (max-width:767px) {
  .segmentation {
    margin-bottom: 500px !important; }

  .bottom.attached.segment {
    width: 100% !important;
    margin-top: 40px !important; }
  .hero-contact,
  .hero-companies,
  .hero-divisions,
  .hero-about-us,
  .hero-companies {
    padding: $hero-padding-mobile; }

  .hero-contact {
    h1 {
      font-size: 14px; } }
  .form-wrap {
    .row {
      .ui.form {
        width: 100% !important;
        margin-top: 50px !important;
        padding: 0 !important;
        border: none !important; } } }

  //  google map overlay used in order to prevent zoom
  //  on the map when the user scrolls
  //  the page with the cursor over the map
  .overlay-map {
    height: 400px !important;
    position: absolute;
    left: 0;
    width: 100%;
    top: 720px; }
  #map {
    height: 400px !important;
    margin: 0 10px !important; }

  .footer {
    text-align: center !important; }
  .info .row .column ul li {
    font-size: 14px !important; }

  .summary {
    .items {
      .item {
        text-align: center;
        i {
          margin: auto;
          padding-bottom: 40px;
          font-size: 35px; }
        a {
          font-size: 18px; } } } } }



// all screens except mobile
@media screen and (min-width:768px) {
  #map,
  .overlay-map {
    margin-top: 50px !important; } }

