
// BEGIN - testimonials slider
.testimonials-slider {
    .row {
        margin: 20px 0; } }

// .iet-popup
//   display: none !important

.flex-container a:hover, .flex-slider a:hover {
    outline: none; }

.testimonials-logo {
  background-color: white !important;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  .ui.image {
    top: 50%;
    text-align: center;
    position: relative;
    transform: translateY(-50%);
    color: white;
    &.small {
      width: 100px; } } }


.slides {
    margin: 0;
    padding: 0;
    list-style: none {
        color: white !important; }
    li {
        margin: 0;
        padding: 0;
        list-style: none;
        color: white !important;
        p {
            font-size: 20px;
            color: white !important; } } }
.extra {
        p {
                color: white;
                float: right;
                font-size: 18px !important; } }

.flex-control-nav {
        li {
                z-index: 99 !important; } }

ol.flex-control-nav {
        z-index: 99 !important;
        margin: auto !important;
        width: 100% !important;
        li {
                a {
                        background: #ccc !important; } } }

.testimonials-wrapper {
    background: url(../img/testimonials-background.jpg) center center !important;
    background-size: cover;
    box-shadow: inset 0 0 0 2038px rgba(0, 0, 0, 0.6);
    background-attachment: fixed !important;
    position: relative;
    .testimonials {
      .content {
        padding: 50px 0 0 0;
        i {
          font-size: 35px;
          // border-bottom: 1px solid white
          padding-bottom: 50px;
          &::before {
            position: absolute;
            border-bottom: 1px solid #2380C5;
            width: 100px;
            margin-left: -45px;
            -webkit-transition: .4s ease-in-out; } }


        .header {
          font-family: 'Open Sans';
          font-weight: 100;
          font-size: 40px; } } } }


.flex-control-nav, .flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none; }

.flex-pauseplay span {
    text-transform: capitalize; }

.flexslider {
    margin: 50px 0 100px 0 !important;
    padding: 0;
    background: transparent !important;
    border: none !important;
    .slides {
        > li {
            display: none;
            -webkit-backface-visibility: hidden;
            p {
                    color: white; } }

        img {
            display: block; }
        &:after {
            content: "\0020";
            display: block;
            clear: both;
            visibility: hidden;
            line-height: 0;
            height: 0; } } }

html[xmlns] .flexslider .slides {
    display: block; }

* html .flexslider .slides {
    height: 1%; }

.no-js .flexslider .slides > li:first-child {
    display: block; }

.flexslider {
    margin: 0 0 60px;
    background: #ffffff;
    border: 4px solid #ffffff;
    position: relative;
    zoom: 1;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
    -o-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
    box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
    .slides {
        zoom: 1;
        img {
            height: auto;
            -moz-user-select: none; } } }

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

.loading .flex-viewport {
    max-height: 300px; }

.flex-direction-nav {
    *height: 0;
    a {
        text-decoration: none;
        display: none;
        width: 40px;
        height: 40px;
        margin: -20px 0 0;
        position: absolute;
        top: 50%;
        z-index: 10;
        overflow: hidden;
        opacity: 0;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.8);
        text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &:before {
            font-family: "flexslider-icon";
            font-size: 40px;
            display: inline-block;
            content: '\f001';
            color: rgba(0, 0, 0, 0.8);
            text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3); }
        &.flex-next:before {
            content: '\f002'; } }
    .flex-prev {
        left: -50px; }
    .flex-next {
        right: -50px;
        text-align: right; } }

.flexslider:hover .flex-direction-nav {
    .flex-prev {
        opacity: 0.7;
        left: 10px;
        z-index: 999;
        &:hover {
            opacity: 1; } }
    .flex-next {
        opacity: 0.7;
        right: 10px;
        z-index: 9999;
        cursor: pointer;
        &:hover {
            opacity: 1; } } }

.flex-direction-nav .flex-disabled {
    opacity: 0 !important;
    filter: alpha(opacity = 0);
    cursor: default;
    z-index: -1; }

.flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000;
    &:before {
        font-family: "flexslider-icon";
        font-size: 20px;
        display: inline-block;
        content: '\f004'; }
    &:hover {
        opacity: 1; }
    &.flex-play:before {
        content: '\f003'; } }

.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
    left: 0;
    li {
        margin: 0 6px;
        display: inline-block;
        zoom: 1;
        *display: inline; } }

.flex-control-paging li a {
    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    &:hover {
        background: #333;
        background: rgba(255, 255, 255, 0.7) !important; }
    &.flex-active {
        background: #000;
        background-color: #2185d0 !important;
        cursor: default; } }

.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
    li {
        width: 25%;
        float: left;
        margin: 0; }
    img {
        height: auto;
        display: block;
        opacity: .7;
        cursor: pointer;
        -moz-user-select: none;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        &:hover {
            opacity: 1; } }
    .flex-active {
        opacity: 1;
        cursor: default; } }


@media screen and (max-width: 860px) {
    .flex-direction-nav {
        .flex-prev {
            opacity: 1;
            left: 10px; }
        .flex-next {
            opacity: 1;
            right: 10px; } } }

// END - testimonials slider


.group-wrap {
  padding: 20px 0 50px 0!important; }


.description-summary {
  color: black; }

.beforePartners {
    .intro {
        background: url('../img/earth.jpg') center center;
        margin: 50px 0 10px 0!important;
        background-size: cover;
        background-attachment: fixed;
        box-shadow: inset 0 0 0 2038px rgba(0,0,0,.5);
        padding: 70px 0 100px 0 !important;
        .column {
          padding: 30px 0 0 0!important;
          .grid {
            .content {
              i {
                margin-left: -7% !important; }
              .header {
                padding: 10px 0 !important;
                color: white;
                position: relative;
                padding-bottom: 5px;
                font-family: 'Open Sans';
                font-weight: 400;
                font-size: 32px !important; } }
            .column {
              .header {
                  font-family: 'Open Sans';
                  font-weight: 300 !important;
                  letter-spacing: 1;
                  color: white !important;
                  font-size: 28px; }
              .button {
                margin: 50px 0 0 0;
                font-family: 'Open Sans';
                font-weight: 400;
                transition: 0.3s ease; }
              .button:hover {
                background-color: white !important;
                color: #2185D0 !important;
                transition: 0.3s ease; } } } } } }


.industries {
    margin: 80px 0 70px 0 !important; }

.index-section {
  padding: 40px 0 0 0;
  color: #37b757;
  &::before {
    content: "";
    position: absolute;
    // width: inherit
    height: 1px;
    // margin: 0 auto !important
    padding-bottom: 60px;
    border-bottom: 1px solid #f2711c;
    -webkit-transition: .4s ease-in-out;
    border-radius: 2px !important; } }

.group {
    margin: 30px 0 30px 0 !important;
    .row {
        h1.header {
          font-family: 'Open Sans' !important;
          font-weight: 200 !important;
          letter-spacing: 1;
          color: #6383b3;
          display: inline-block;
          position: relative;
          padding-bottom: 5px;
          &::before {
            content: "";
            position: absolute;
            width: 70%;
            height: 1px;
            bottom: -10px;
            left: 15%;
            border-bottom: 1px solid #6383B3;
            -webkit-transition: .4s ease-in-out;
            border-radius: 2px !important; } } }
    &.clients {
      margin-top: 60px !important; }
    &.global {
      margin: 80px auto 0 auto !important; } }


.geo-seg {
  .header {
    strong {
      color: #6383B3;
      font-weight: 900; } } }

.group-cards {
    // background-color: #B9D3E9
    .cards {
      .card {
        box-shadow: none;
        .header {
          padding: 15px 0 0 0;
          font-family: 'Open Sans';
          font-weight: 500;
          padding-bottom: 15px;
          border-bottom: 1px solid #eee;
          &:hover {
            color: #478BE2; } }
        .image {
          margin: 10px auto 20px auto; } } } }

// hidden description for industries cards
.toggle-summary {
  display: none; }

// button for industries cards
.readmore-summary {
  margin-top: 20px !important; }

// .partners
//     margin: 0 auto !important
//     p
//         width: 50%
//         background-color: lightgray
//         padding: 10px 150px 10px 50rpx
//         font-weight: 500 !important
//         font-family: 'Open Sans' !important
//         background: linear-gradient(to right , lightgray, #F2F2F2, transparent )

.partners-logos {
  padding: 40px 0 110px 0 !important; }


// call to action section right above footer
.outro {
    padding: 45px 0 !important;
    border-top: 1px solid lightgray;
    // background: url('../img/earth.jpg') center center !important
    background-size: cover !important;
    // box-shadow: inset 0px 0px 0px 2000px rgba(0,0,0,0.6)
    background-attachment: fixed !important;
    margin-bottom: 15px !important;
    .column {
        padding: 20px 0 !important;
        .grid {
            .column {
                .header {
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-size: 28px;
                    padding-bottom: 20px;
                    color: #2185D0;
 }                    // color: white
                .button {
                    // color: white !important
                    font-family: 'Open Sans';
                    font-weight: 400; }
                .button:hover {
                    // background-color: white !important
                    background-color: #2185D0 !important;
                    color: white !important; } } } } }



.outro-message {
    padding: 40px 0 !important;
    .column {
        padding: 20px 0 !important;
        .grid {
            .column {
                .header {
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-size: 28px;
                    color: #2989D1; } } } } }

// headquaters popups on global map
.global-map {
    padding: 40px 0 !important;
    .column {
        background: url(../img/global-map.png);
        background-repeat: no-repeat !important;
        background-size: cover;
        background-position: 0% 100%;
        height: 610px;
        z-index: 99 !important;
        .teakwood,
        .oradea {
            width: 10px;
            height: 10px;
            //background-color: black
            border-radius: 50%;
            position: absolute; }
        .teakwood {
            margin-top: 135px;
            margin-left: 220px; }
        .oradea {
            margin-top: 125px;
            margin-left: 575px; } } }

// global map popups
.teakwood-description,
.oradea-description {
    margin-left: -15px !important;
    display: block !important;
    visibility: visible !important;
    cursor: pointer;
    user-select: none;
    &.clicked {
      background-color: lightgray !important;
      &::before {
        background-color: lightgray !important; } } }

.contact {
    margin-bottom: 50px !important; }

// laptop only
@media (max-width: 1199px) and (min-width: 992px) {
  .teakwood {
    margin-left: 236px !important; }
  .oradea {
    margin-left: 626px !important; }
  .description-summary {
    padding: 0 6% !important; } }

// tablet only
@media (max-width: 991px) and (min-width: 768px) {
  .teakwood {
    margin-left: 215px !important; }
  .oradea {
    margin-left: 575px !important; } }

// mobile only
@media screen and (max-width: 767px) {

  .group-wrap {
    padding: 20px 0 50px 0!important;
    .industries-cards {
      .ui.card {
        img {
          margin-top: 35px !important; } } } }

  .slidergrid {
 }    // display: none !important
  .title {
      margin-top: -1vh !important;
      font-size: 1.3rem; }
  .subtitle {
      font-size: 1rem;
      margin-top: -1rem !important; }
  .hero-button {
      display: none !important; }
  .bjqs-markers {
      margin-top: 1.4rem !important; }

  .global-map {
      padding-top: 0px !important;
 }      // display: none !important


  .contact-cards {
    margin-top: 50px !important;
    .cards {
      .card {
        margin-top: 40px !important;
        &:last-of-type {
          margin-bottom: 50px !important; } } } }

  .slides {
      li {
          p {
              font-size: 16px;
              text-align: center;
              font-weight: 400 !important; } } }
  .extra {
      p {
          text-align: center; } }

  .outro {
    .column {
      .grid {
        .column {
          .header {
            font-size: 16px; }
          .button {
            font-size: 14px; } } } } }

  .geo-seg {
    .header {
      font-size: 22px !important; } }

  .global {
    .row {
      .header {
        font-size: 20px; } } }

  .partners {
    .clients {
      .row {
        .header {
          font-size: 18px; } } } } }






