.timeline-year {
    position: absolute;
    background-color: #2380c5;
    border-radius: 50%;
    // position the year in center of box
    .date {
        text-align: center;
        position: relative;
        top: 50%;
        transform: translate(-10px, -10px);
        color: white; } }

#hideBtn {
    display: none; }

.readmore {
    &.active {
        background-color: red !important; } }

.logo-placeholder {
    width: 70px;
    height: 70px;
    background-color: #2380c5;
    border-radius: 50%;
    position: relative;
    .placeholder-initials {
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        color: white;
        font-size: 1.4em; } }


.storyline {
    padding: 20px 0 !important;
    .row {
        font-size: 2em;
        font-weight: 100;
        text-align: center; } }

.hiddenDescription {
    display: none; }

// center aligned vertical line
#timeline {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 4px;
        background: rgba(0,0,0,0.2); } }

// position the cards vertically
.timeline-block {
    visibility: hidden; // initially remove visibility for semantic ui transition
    margin: 0 0 50px 0;
    &:last-of-type {
        margin-bottom: 80px !important; }
    // vertical space between the cards
    &:after {
        content: "";
        display: table;
        clear: both; }

    .timeline-content {
        position: relative;
        left: 0px; // position the cards closer to the middle bullets
        h3.ui.header {
            .ui.tiny.image {
                width: 60px !important; }
            .ui.small.image {
                width: 120px !important; } }
        .section {
            font-size: 1.2em; } }
    &:nth-of-type(1) {
        .timeline-content {
            h3.ui.header {
                .content {
                    &:nth-of-type(2) {
                        // background-color: red
                        margin-top: 15px;
                        padding-left: 10px; } } } } }

    &:nth-of-type(3) {
        .timeline-content {
            h3.ui.header {
                padding: 17px 0 !important; } } }

    &:nth-of-type(10) {
        @extend .timeline-block:nth-of-type(1); } }


// right arrow for cards
.timeline-block:nth-of-type(odd) {
    .timeline-content {
        &::before {
            content: '';
            position: absolute;
            top: 16px;
            right: 100%;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 15px solid white !important;
            z-index: 11; }
        &::after {
            content: '';
            position: absolute;
            top: 15px;
            left: 100%;
            width: 0;
            z-index: 10;
            height: 0;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
            border-left: 16px solid lightgray; } } }

// left arrow cards
.timeline-block:nth-of-type(even) {
    .timeline-content {
        margin-top: 0px;
        &::before {
            content: '';
            position: absolute;
            top: 16px !important;
            right: 100%;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-right: 15px solid white !important;
            z-index: 11; }
        &::after {
            content: '';
            position: absolute;
            top: 15px;
            right: 100%;
            width: 0;
            z-index: 10;
            height: 0;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
            border-right: 16px solid lightgray; } } }

// mobile view only
@media screen and (max-width: 767px) {
    #timeline {
        padding: 10px;
        &::before {
            display: none; }
        .timeline-block {
            margin: 20px auto;
            .timeline-year {
                .date {
                    display: none {
                            transform: translate(-10px, -10px); } } }
            .timeline-content {
                &::before {
                    display: none; }
                &::after {
                    display: none; }
                h3.ui.header {
                    text-align: center;
                    .logo-placeholder {
                        margin: 0 auto;
                        .placeholder-initials {} }
                    img {
                        display: block !important;
                        margin: 0 auto !important; } }

                // .readmore
                //     margin-left: 50%
 } } } }                //     transform: translateX(-50%)


// treating just arrows
// arrows all pointing to left on tablet
@media (max-width: 991px) and (min-width: 768px) {   // TABLET VIEW
    #timeline {
        &::before {
            left: 4%; }
        .timeline-block {
            margin: 20px 0;
            .timeline-year {
                width: 40px;
                height: 40px;
                .date {
                    transform: translate(-2px, -10px); } }
            .timeline-content {
                // left: 10px // position the cards closer to the middle bullets
                width: 90%;
                float: right; } } }



    .timeline-block:nth-of-type(odd) {
        .timeline-content {
            margin-top: 0px;
            &::before {
                top: 6px;
                left: -2.3%;
                transform: rotate(180deg);
                border-left: 15px solid white !important; }
            &::after {
                top: 5px;
                left: -2.5%;
                transform: rotate(180deg);
                border-left: 16px solid lightgray; } } }

    .timeline-block:nth-of-type(even) {
        .timeline-content {
            &::before {
                top: 6px !important;
                right: 100%;
                border-right: 15px solid white !important; }
            &::after {
                top: 5px;
                right: 100%;
                border-right: 16px solid lightgray; } } } }


// all screens starting from small monitors (laptop) - except mobile and tablet
@media only screen and (min-width: 992px) {
    // position the middle vertical gray line
    #timeline {
        &::before {
            left: 50%; } }

    // middle years bullets on the vertical bar
    .timeline-year {
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden; }

    // positioning for cards
    .timeline-content {
        margin-left: -50px; // add space between vertical line and the left side cards
        padding: 1.6em; // add padding to all cards
        width: 45%; // width of the cards
        &::before {
            left: 100%; } } // right pointing arrows positioning on the right side of the card

    // align even cards to right
    .timeline-block:nth-child(even) .timeline-content {
        float: right;
        // left pointing arrows positioning
        &::before {
            top: 24px;
            left: auto; } } }







