.world.icon {
	color: #2A3035 !important; }

// .launch
//   display: none !important

.sidebar.menu {
	.top.right.attached.label {
		background-color: white;
		margin-top: 3px;

		i {
			font-size: 26px;
			&:hover {
				color: black !important; } } }
	h1.header.sidebar-menu {
		padding: 20px 0;
		// &.menu-after-scroll
		// 	background: rgba(255, 255, 255, 1)
		// 	transition: .5s ease
		// 	padding: 4.5px
		img {
			margin: 0 auto; } }

	.item {
		color: #2A3035 !important;
		.content {
			font-size: 20px;
			text-align: left;
			font-family: 'Open Sans';
			padding: 22px 34px;
			font-weight: 100;
			width: 280px;
			i {
				float: right !important;
				vertical-align: middle !important;
				display: block !important;
				font-size: 26px !important;
				bottom: 3.5px !important; } }
		&.active {
			color: #1c4e93; }
		&:hover {
			color: #1c4e93 !important; }
		// &:first-of-type
		// 	.content
		// 		padding: 0 10px
		// 		i
		// 			float: left
		// 			padding: 0 !important
		// 		&:hover
 } }		// 			color: #D95C5C



.menu-wraper {
	background: rgba(255, 255, 255, 1);
	// position: fixed !important
	width: 100%;
	z-index: 9999999 !important;
	padding: 16px 0;
	-webkit-transition: 0.4s ease;
	-moz-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
	&.menu-after-scroll {
		background: rgba(255, 255, 255, 1);
		transition: .5s ease;
		padding: 0;
		box-shadow: 0px 0px 4px lightgray; }
	.launch {
		// background: rgba(0,0,0,0.9) !important
  //border-radius: 3% !important
		padding: 5px 15px !important;
		transition: .05s ease !important;
		i.at-top-menu {
			margin: 0 !important;
			color: #2A3035 !important; }
		span {
			font-size: 1.25rem !important;
			vertical-align: middle !important;
			font-weight: 600;
			font-family: Lato, 'Helvetica Neue', 'Arial';
			color: #2A3035 !important; }
		&:hover {
			background-color: rgba(0,0,0,.05) !important;
			transition: .05s ease !important; }
		&:hover i,
		&:hover span {
			color: #1c4e93 !important; } } }

i.at-top-menu {
	color: #2A3035 !important; }
i.sidebar.icon {
	color: #2A3035 !important;
	margin-right: 0 !important; }
.dropdown {
	.text {
		font-weight: 900 !important;
		font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
		color: #2A3035;
		font-size: 18px; } }
.ui.text.menu {
	margin: 0 60px 0 30px; }

.ui-link {
  margin-right: 10px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  color: #194993 !important;
  padding: 6px !important; }

.item.launch {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important; }

.ui.item.active {
  .content {
    .ui-link {
      border: 1px solid #1b4d93;
      color: #1b4d93 !important;
      border-radius: 5px; } } }

.ui-link:hover {
  // border: 1px solid #000
  color: #1F6DCA !important; }

.first-item {
  margin-left: 30px !important; }

.lang-item {
 }  // text-transform: uppercase


.ui.menu .ui.dropdown .menu .item {
	padding: 18px 18px !important; }
// .dropped.menu
// 	.item
// 		span
// 			font-size: 18px
// 		&:first-of-type
// 			margin-top: 10px !important

@media screen and (max-width: 991px) {
  .ui-link {
    font-size: 14px;
    padding: 2px !important;
    margin-right: 0; }
  .first-item {
    margin-left: 5px !important; }
  .right.text.menu {
    a.item {
      font-size: 10px !important; } } }

@media screen and (max-width: 767px) {
	.menu-wraper {
		padding: 4px 0;
		&.menu-after-scroll {
			padding: 4px 0; } }

	.ui.text.menu {
    margin: 0 14px 0 0;
    .ui.container {
      .item {
        font-size: 10px;
        span {
          font-size: 16px !important; }
        .text {
          font-size: 16px; } }
      .right.text.menu {
        a.item {
          font-size: 10px !important; } }
      .item.hideLogoOnMobile {
        display: none; }
      .item.hideMobile {
        display: none; }
      .item.launch {
        position: relative !important;
        top: 0 !important;
        left: 0 !important; } } }

	.sidebar.menu {
		.item {
			.content {
				padding: 5px 0 !important;
				width: 190px; } } } }
