
#policies {
  margin-bottom: 50px;

  ul {
    padding-inline-start: 40px;
    margin-bottom: 20px; }

  p,ul,ol {
    font-size: 18px !important;
    font-weight: 100;
    line-height: 1.6; }

  .title {
    .header {
      font-size: 36px;
      font-weight: 300; } }

  .subtitle {
    margin-top: 30px;
    margin-bottom: 10px;

    .header {
      font-size: 22px; }
    .subheader {
      font-size: 18px; } } }

.bold {
  font-weight: bold; }

.f-right {
  float: right; }
