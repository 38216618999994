.description-label {
  margin-top: 15px;
  .ui.header {
    color: white;
    font-size: 1rem;
    text-align: center !important;
    width: 130px;
    padding: 5px 0;
    margin-left: -28px; } }

ul {
  margin: 0;
  padding: 0; }

// .iet-popup
//   display: none !important

.cd-full-width {
  h2 {} }





.nav-items {
  li {
    a {
      img {
        display: block !important;
        position: relative !important;
        max-width: 100% !important;
        background-color: transparent !important;
        vertical-align: middle !important; } } } }

.cd-header {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #21272c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media only screen and (min-width: 768px) {
  .cd-header {
    height: 70px;
    background-color: transparent; } }

#cd-logo {
  float: left;
  margin: 13px 0 0 5%;
  img {
    display: block; } }

@media only screen and (min-width: 768px) {
  #cd-logo {
    margin: 23px 0 0 5%; } }

.cd-primary-nav {
  float: right;
  margin-right: 5%;
  width: 44px;
  height: 100%;
  ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-10%);
    -moz-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    -o-transform: translateY(-10%);
    transform: translateY(-10%);
    &.is-visible {
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
      -webkit-transform: translateY(50px);
      -moz-transform: translateY(50px);
      -ms-transform: translateY(50px);
      -o-transform: translateY(50px);
      transform: translateY(50px); } }
  a {
    display: block;
    height: 50px;
    line-height: 50px;
    padding-left: 5%;
    background: #21272c;
    border-top: 1px solid #333c44;
    color: #ffffff; } }

@media only screen and (min-width: 768px) {
  .cd-primary-nav {
    width: auto;
    height: auto;
    background: none;
    ul {
      position: static;
      width: auto;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      line-height: 70px;
      &.is-visible {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0); } }
    li {
      display: inline-block;
      margin-left: 1em; }
    a {
      display: inline-block;
      height: auto;
      font-weight: 600;
      line-height: normal;
      background: transparent;
      padding: .6em 1em;
      border-top: none; } } }


.cd-hero {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.cd-hero-slider {
  position: relative;
  margin-top: 0px;
  height: 800px !important;
  overflow: hidden;
  li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    list-style-type: none;
    box-shadow: inset 0px 0px 0px 2000px rgba(0,0,0,0.6);
    &:first-of-type {
      box-shadow: inset 0px 0px 0px 2000px rgba(0,0,0,0);
      background-size: cover !important;
      .cd-full-width {}
      background-color: #234C6D !important;
      padding-top: 0px !important; }
    &.selected {
      position: relative;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); }
    &.move-left {
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%); }
    &.is-moving, &.selected {
      -webkit-transition: -webkit-transform 0.5s;
      -moz-transition: -moz-transform 0.5s;
      transition: transform 0.5s;
      background-position: center center !important;
      background-size: cover;
      background-repeat: no-repeat; }
    &:nth-of-type(12) {
      background: url('../img/slider-geobeta-orvi.jpg'); }
    &:nth-of-type(11) {
      background: url('../img/slider-geobeta-real-estate-iet.jpg'); }
    &:nth-of-type(10) {
      background: url('../img/slider-geobeta-real-estate-ro-v1.jpg');
      box-shadow: inset 0px 0px 0px 2000px rgba(0,0,0,0.4); }
    &:nth-of-type(9) {
      background: url('../img/slider-geobeta-transportation.jpg'); }
    &:nth-of-type(8) {
      background: url('../img/slider-geobeta-construction.jpg'); }
    &:nth-of-type(7) {
      background: url('../img/slider-geobeta-woodworks.jpg'); }
    &:nth-of-type(6) {
      background: url('../img/slider-geobeta-software-development.jpg'); }
    &:nth-of-type(5) {
      background: url('../img/slider-geobeta-automation.jpg'); }
    &:nth-of-type(4) {
      background: url('../img/slider-geobeta-manufacturing.jpg') !important; }
    &:nth-of-type(3) {
      background: url('../img/slider-geobeta-design-engineering.jpg') !important; }
    &:nth-of-type(2) {
      background: url('../img/slider-geobeta-project-management.jpg') !important; }
    &:first-of-type {
      // background-color: #2c343b
      //background: url('../img/slider-geobeta-first-page.jpg')
      // background: url('../img/worldmapdot2.jpg')
 } }      //


  .cd-full-width {
    .ui.button {
      box-shadow: none !important;
      border: 1px solid white;
      &:hover {
        box-shadow: none !important; }
      &:selected {
        box-shadow: none !important; }
      &:focus {
        box-shadow: none !important; } } }
  .cd-full-width, .cd-half-width {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    padding-top: 0px;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0); }
  .cd-img-container {
    display: none;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      bottom: auto;
      right: auto;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -o-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%); } }
  .cd-bg-video-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  h2, p {
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    line-height: 1.2;
    margin: 0 auto 14px;
    color: #ffffff;
    max-width: 820px; }
  h2 {
    font-size: 2.4rem; }
  p {
    font-size: 1.8rem;
    line-height: 1.4; }
  .cd-btn {
    display: inline-block;
    // padding: 1.2em 1.4em
    // margin-top: .8em
    // background-color: rgba(212, 68, 87, 0.9)
    // font-size: 1.3rem
    font-weight: 200;
    // letter-spacing: 1px
    // color: #ffffff
    // text-transform: uppercase
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1)
    // -webkit-transition: background-color 0.2s
    // -moz-transition: background-color 0.2s
    // transition: background-color 0.2s
    &.secondary {
 }      // background-color: rgba(22, 26, 30, 0.8)
    &:nth-of-type(2) {
 } } }      // margin-left: 1em

// tablet view and up
@media only screen and (min-width: 768px) {
  .cd-hero-slider {
    height: 500px; } }

// small monitor view and up
@media only screen and (min-width: 1170px) {
  .cd-hero-slider {
    height: 680px; } }



.no-touch .cd-hero-slider .cd-btn {
  &:hover {
    background-color: none; }
  &.secondary:hover {
    background-color: #161a1e; } }

// all views except mobile
@media only screen and (min-width: 768px) {
  ul.cd-hero-slider {
    li {
      &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4) {
        background-image: none; }
      &:first-of-type .cd-full-width {
        padding-top: 0px; } }
    .cd-full-width {
      padding-top: 200px; }
    .cd-bg-video-wrapper {
      display: block; }
    .cd-half-width {
      width: 45%;
      &:first-of-type {
        left: 5%; }
      &:nth-of-type(2) {
        right: 5%;
        left: auto; } }
    .cd-img-container {
      display: block; }
    h2, p {
      max-width: 1020px; }
    h2 {
      font-size: 2.4em;
      font-weight: 900;
      font-family: 'Open Sans', Arial Black; }
    .cd-btn {
      font-size: 1.4rem; } } }


// all views starting from very small monitors except mobile and tablet
@media only screen and (min-width: 1170px) {
  ul.cd-hero-slider {
    li {
      &:first-of-type .cd-full-width {
        padding-top: 40px !important; }
      .cd-full-width {
        padding-top: 200px; }
      h2, p {
        margin-bottom: 20px; }
      h2 {
        font-size: 3.2em; }
      p {
        font-size: 2.4rem; } } } }

// all views starting from tablet -- except mobile
@media only screen and (min-width: 768px) {
  .cd-hero-slider {
    .cd-half-width {
      opacity: 0;
      -webkit-transform: translateX(40px);
      -moz-transform: translateX(40px);
      -ms-transform: translateX(40px);
      -o-transform: translateX(40px);
      transform: translateX(40px); }
    .move-left .cd-half-width {
      -webkit-transform: translateX(-40px);
      -moz-transform: translateX(-40px);
      -ms-transform: translateX(-40px);
      -o-transform: translateX(-40px);
      transform: translateX(-40px); }
    .selected .cd-half-width {
      opacity: 1;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); }
    .is-moving .cd-half-width {
      -webkit-transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
      -moz-transition: opacity 0s 0.5s, -moz-transform 0s 0.5s;
      transition: opacity 0s 0.5s, transform 0s 0.5s; }
    li.selected {
      &.from-left .cd-half-width:nth-of-type(2), &.from-right .cd-half-width:first-of-type {
        -webkit-transition: opacity 0.4s 0.2s, -webkit-transform 0.5s 0.2s;
        -moz-transition: opacity 0.4s 0.2s, -moz-transform 0.5s 0.2s;
        transition: opacity 0.4s 0.2s, transform 0.5s 0.2s; }
      &.from-left .cd-half-width:first-of-type, &.from-right .cd-half-width:nth-of-type(2) {
        -webkit-transition: opacity 0.4s 0.4s, -webkit-transform 0.5s 0.4s;
        -moz-transition: opacity 0.4s 0.4s, -moz-transform 0.5s 0.4s;
        transition: opacity 0.4s 0.4s, transform 0.5s 0.4s; } }
    .cd-full-width {
      h2, p, .cd-btn {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px); } }
    .move-left .cd-full-width {
      h2, p, .cd-btn {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px); } }
    .selected .cd-full-width {
      h2, p, .cd-btn {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); } }
    li {
      &.is-moving .cd-full-width {
        h2, p, .cd-btn {
          -webkit-transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
          -moz-transition: opacity 0s 0.5s, -moz-transform 0s 0.5s;
          transition: opacity 0s 0.5s, transform 0s 0.5s; } }
      &.selected {
        h2 {
          -webkit-transition: opacity 0.4s 0.2s, -webkit-transform 0.5s 0.2s;
          -moz-transition: opacity 0.4s 0.2s, -moz-transform 0.5s 0.2s;
          transition: opacity 0.4s 0.2s, transform 0.5s 0.2s; }
        p {
          -webkit-transition: opacity 0.4s 0.3s, -webkit-transform 0.5s 0.3s;
          -moz-transition: opacity 0.4s 0.3s, -moz-transform 0.5s 0.3s;
          transition: opacity 0.4s 0.3s, transform 0.5s 0.3s; }
        .cd-btn {
          -webkit-transition: opacity 0.4s 0.4s, -webkit-transform 0.5s 0.4s, background-color 0.2s 0s;
          -moz-transition: opacity 0.4s 0.4s, -moz-transform 0.5s 0.4s, background-color 0.2s 0s;
          transition: opacity 0.4s 0.4s, transform 0.5s 0.4s, background-color 0.2s 0s; } } } } }

// wrapper for logo bullets on the slider
.cd-slider-nav {
  position: absolute;
  width: 100%;
  bottom: 95px;
  z-index: 2;
  text-align: center;
  height: 55px;
  background-color: rgba(0, 1, 1, 0);
  nav, ul, li, a {
    height: 100%; }
  nav {
    display: inline-block;
    position: relative; }
  .cd-marker {
    position: absolute;
    bottom: 0;
    left: 10px;
    width: 60px;
    height: 100%;
    display: none !important;
    border-radius: 20%;
    &.item-1 ~ .nav-items .geobeta-popup a img,
    &.item-2 ~ .nav-items .iet-popup a img,
    &.item-3 ~ .nav-items .gmab-popup a img,
    &.item-4 ~ .nav-items .euroam-popup a img,
    &.item-5 ~ .nav-items .arc-popup a img,
    &.item-6 ~ .nav-items .mandfit-popup a img,
    &.item-7 ~ .nav-items .lemnest-popup a img,
    &.item-8 ~ .nav-items .amecon-popup a img,
    &.item-9 ~ .nav-items .flamarex-popup a img,
    &.item-10 ~ .nav-items .imobinvest-popup a img,
    &.item-11 ~ .nav-items .mfgk-popup a img,
    &.item-12 ~ .nav-items .orvi-popup a img, {
      filter: grayscale(0%) !important;
      transition: all 0.6s ease; } }
  ul::after {
    clear: both;
    content: "";
    display: table; }
  li {
    display: inline-block;
    width: 60px;
    float: left;
    &.selected .cd-marker {
      color: #2c343b;
      background-color: red !important; } } }

.no-touch .cd-slider-nav li.selected a:hover {
  background-color: transparent; }

.cd-slider-nav a {
  display: block;
  position: relative;
  // padding-top: 35px
  font-size: 1rem;
  font-weight: 700;
  color: #a8b4be;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
  &::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 8px;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); } }

.no-touch .cd-slider-nav a:hover {
  background-color: rgba(0, 1, 1, 0.5); }


// all views starting from tablet -- except mobile
@media only screen and (min-width: 768px) {
  .cd-slider-nav {
    height: 90px;
    .cd-marker, li {
      width: 90px; }
    li {
      border-radius: 20%;
      background-color: white;
      padding: 10px;
      border: 1.5px solid gray;
      &:first-of-type {
 }        // display: none
      a {
        font-size: 1rem;
        text-transform: uppercase;
        &::before {
          top: 18px; } }
      &:nth-of-type(1) {
        a {
          img {
            margin-top: 0px; } } }
      &:nth-of-type(3) {
         a {
          img {
            margin-top: 0px; } } }
      &:nth-of-type(2) {
        a {
          img {
            margin-top: 0px !important; } } } } }
  .nav-items {
    li {
      margin: 0 20px;
      transition: all .2s ease-in-out;
      &.selected {
        box-shadow: 0px 0px 60px white !important;
        transform: scale(1.1); }
      &:first-of-type {}
      a {
        img {
          border-radius: 0%;
          filter: grayscale(100%);
          transition: all 1s ease; } } } }

  .nav-items {
    li {
      &.hovered-item {
        transform: scale(1.1);
        box-shadow: 0px 0px 60px white !important; } } } }

// there are more logos now
@media (max-width: 1575px) and (min-width: 1432px) {
  .nav-items {
    li {
      margin: 0 15px; } }
  .cd-slider-nav {
    height: 78px;
    .cd-marker, li {
      width: 78px; } } }

// still because of having more logos
@media (max-width: 1431px) and (min-width: 1199px) {
  .nav-items {
    li {
      margin: 0 10px; } }
  .cd-slider-nav {
    height: 78px;
    .cd-marker, li {
      width: 78px; } } }

// only laptop view
@media (max-width: 1199px) and (min-width: 992px) {

  .description-label {
    // margin-top: 15px
    .ui.header {
      // color: white
      font-size: 0.9rem;
      // text-align: center !important
      width: 120px;
      // padding: 5px 0
 } }      // margin-left: -28px

  .nav-items {
    li {
      margin: 0 5px; } }

  .cd-slider-nav {
    height: 60px;
    .cd-marker, li {
      width: 60px; } }

  .cd-marker {
    position: absolute;
    bottom: 0;
    left: 10px;
    width: 60px;
    height: 100%;
    border-radius: 20%; } }


.cd-main-content {
  width: 90%;
  max-width: 768px;
  margin: 0 auto;
  padding: 2em 0;
  p {
    font-size: 40rem;
    line-height: 1.8;
    color: #999999;
    margin: 2em 0; } }

// only mobile view
@media screen and (max-width: 767px) {

  .description-label {
    bottom: 60px !important;
    position: absolute;
    font-family: 'Open Sans' !important;
    font-weight: 100 !important;
    width: 100%;
    left: 0 !important;
    .ui.header {
      color: white;
      font-size: 1rem;
      // text-align: center !important
      width: 200px;
      margin: 0 auto;
      // padding: 5px 0
 } }      // margin-left: -28px

  .cd-hero-slider {
    height: 480px !important;
    margin-top: 0px;
    li {
      .cd-full-width {
        h2,
        p {
          font-size: 1.1rem;
          width: 90%;
          margin: 10px auto; }
        h2 {
          margin-top: 40px !important; } }
      &:nth-of-type(8),
      &:nth-of-type(9),
      &:nth-of-type(10) {
        .cd-full-width {
          h2 {
            margin-top: 100px !important; } } } } }

  .cd-slider-nav {
    height: 50px;
    .cd-marker, li {
      width: 50px; } }

  // .geobeta-slider-logo
  //// display: none !important

  #seeMoreArrow {
    margin-top: 30px;
    .column {
      padding: 0;
      i {
        margin-top: -10px;
        background-color: #D13942;
        border: 1px solid white;
        border-radius: 10%; } } }

  nav {
    margin-top: -120px !important;
    .nav-items {
      margin: 0 auto !important;
      width: 100% !important;
      li {
        background-color: white;
        border-radius: 20%;
        margin: 1.2%;
        a {
          img {
            border-radius: 20%;
            padding: 3px;
            filter: grayscale(100%) !important; } } } } }


  .cd-slider-nav {
    z-index: 2;
    text-align: center;
    position: relative;
    width: 94%;
    margin: 0 auto;
    nav, ul, li, a {
      height: 100%; }
    nav {
      display: inline-block;
      position: relative;
      margin-top: 10px; }
    .cd-marker {
      left: 10px;
      width: 30px; } } }

// only tablet view
@media (max-width: 991px) and (min-width: 768px) {

  .description-label {
    // margin-top: 15px
    .ui.header {
      // color: white
      font-size: 0.8rem;
      // text-align: center !important
      width: 95px;
      // padding: 5px 0
 } }      // margin-left: -28px

  .cd-hero-slider {
    h2, p {
      max-width: 700px; } }

  .cd-slider-nav {
    height: 50px;
    .cd-marker, li {
      width: 50px; } }

  .nav-items {
    li {
      margin: 0 5px; } }
  .cd-marker {
    left: 10px;
    width: 60px; } }


// small monitor views and up -- except mobiles and tablets
@media only screen and (min-width: 1170px) {

  .cd-main-content {
    padding: 3em 0;
    p {
      font-size: 1.6rem; } } }

.no-js {
  .cd-hero-slider li {
    display: none;
    &.selected {
      display: block; } }
  .cd-slider-nav {
    display: none; } }

